<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container" :style="'background: url('+bannerBg+')'">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">VectorCAST</h1>
        <p data-caption-delay="100" class="wow fadeInRight">高质量软件的自动化代码测试工具</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft">
      <div class="container">
        <div class="normal-title">产品介绍</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <div class="intro-text" style="width: 100%">
                <p>VectorCAST是德国Vector公司旗下的一款权威的C/C++代码的自动化测试套件，在完整的Vector测试解决方案中扮演重要角色。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections solution-functions wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div class="all-function">
          <ul class="priority-list">
            <li v-for="(item, index) in functionList" :key="index">
              {{ item.content }}
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="electric-solution-components ecusoft-solution-components wow fadeInLeft">
      <div class="container" style="height: 200px">
        <div class="normal-title">核心组件</div>
        <div class="solution-components">
          <div class="components-content">
            <p>VectorCAST提供VectorCAST/C++和VectorCAST/QA套件。</p>
            <p>VectorCAST/C++用于单元/集成测试，VectorCAST/QA用于系统测试。</p>
          </div>
        </div>
      </div>
    </section>
    <section class="solution-application cast-solution-application wow fadeInRight">
      <div class="container">
        <div class="normal-title">应用领域</div>
        <div class="all-application">
          <div class="application-text">
            <p>除汽车以外，VectorCAST对航空航天、工业、医疗、轨道交通等行业标准有良好的支持，并且得到了相关认证。</p>
            <img src="../../../assets/images/vectorCAST-authentication.png" alt="" class="cast-image"/>
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/vector/cast.less";
import bzInfo from "@/components/BzInfo.vue";
import bannerBg from '@/assets/images/banner-product.jpg';

export default {
  name: "vectorCast",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data() {
    return {
      activeIndex: '3-1',
      bannerBg: bannerBg,
      functionList: [
        {
          id: 1,
          title: "遵循行业标准",
          content: " VectorCAST 遵循SO26262 等行业标准，适用于对自身有高安全性和高可靠性要求的嵌入式系统，功能上覆盖了需求分析、单元测试、集成测试、覆盖率分析等软件测试所涉及的各个环节"
        },
        {
          id: 2,
          title: "支持Windows/Linux平台",
          content: " VectorCAST 支持 Windows/Linux 平台并且可以和 Vector 旗下的静态分析工具 PC-lint Plus(PCLP)和项目质量管理 Squore 看板相结合，降低开发风险，提高代码质量"
        },
        {
          id: 3,
          title: "支持众多的主流编译器",
          content: " VectorCAST 除自身集成有 VectorCAST MINGW 编译器以外，还支持众多的主流编译器，如 Green Hills、TASKING 等。并结合免费通用的模拟器，打造出比较完善的测试解决方案"
        },
        {
          id: 4,
          title: "免费的插件",
          content: "VectorCAST 可以利用免费的插件实现 Jenkins 框架下的持续集成测"
        },
        {
          id: 5,
          title: "图形化界面操作",
          content: "VectorCAST对C++友好支持，完美支持C++11&14&17特性，并针对部分C++特性，实现图形化界面操作"
        }
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 4, // 一屏幕显示几个? 数字或者默认  auto 自动。
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "VectorCAST - 产品中心 - 东信创智"
    new this.$wow.WOW().init()
  },
  methods: {

    linkUrl(url) {
      window.open(url, '_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
